<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 14.007 18.676">
        <path id="Icon_awesome-file-invoice" data-name="Icon awesome-file-invoice" d="M10.505,9.338h-7v2.334h7ZM13.752,3.83,10.18.255A.875.875,0,0,0,9.56,0H9.338V4.669h4.669V4.446A.873.873,0,0,0,13.752,3.83ZM8.171,4.961V0H.875A.873.873,0,0,0,0,.875V17.8a.873.873,0,0,0,.875.875H13.131a.873.873,0,0,0,.875-.875V5.836H9.046A.878.878,0,0,1,8.171,4.961ZM2.334,2.626a.292.292,0,0,1,.292-.292H5.544a.292.292,0,0,1,.292.292V3.21a.292.292,0,0,1-.292.292H2.626a.292.292,0,0,1-.292-.292Zm0,2.334a.292.292,0,0,1,.292-.292H5.544a.292.292,0,0,1,.292.292v.584a.292.292,0,0,1-.292.292H2.626a.292.292,0,0,1-.292-.292Zm9.338,11.089a.292.292,0,0,1-.292.292H8.462a.292.292,0,0,1-.292-.292v-.584a.292.292,0,0,1,.292-.292h2.918a.292.292,0,0,1,.292.292Zm0-7.3v3.5a.583.583,0,0,1-.584.584H2.918a.583.583,0,0,1-.584-.584v-3.5a.583.583,0,0,1,.584-.584h8.171A.583.583,0,0,1,11.672,8.754Z"/>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconAlert',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
    }
}
</script>