<template>
<div class="d-flex flex-column justify-content-center  py-2">
    <div class="hover-blue cursor-pointer col-md-7 d-flex align-items-start align-items-md-center mb-4 px-2" @click="goBack">
        <div class="me-3 mt-2 mt-md-0">
            <icon-arrow :size="25" style="transform: rotate(180deg);" :color="'var(--dark-gray-200)'"/>
        </div>
        <div class="d-flex flex-column align-items-start ms-1">
            <h6 class="title-22 color-blue-700 mb-1">{{$t("checkout.balance_pending")}}</h6>
            <div class="d-flex justify-content-end">
                    <label class="mb-2 subtitle-15"  id="credit">{{$t("checkout.your_credit_limit_balance")}}
                        <span class="text-danger weight-600">{{user.customer.balance | moneyFormat }}</span>
                    </label>
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <lottie :options="defaultOptions" :height="250" :width="250" v-if="loading"/>
        <table v-else class="table quotes-table m-0 align-middle">
            <thead class="text-center align-middle">
                <tr>
                    <th scope="col">{{$t('account.document')}}</th>
                    <th scope="col">{{$t('account.creation')}}</th>
                    <th scope="col">{{$t('account.total')}}</th>
                    <th scope="col">{{$t('account.payment')}}</th>
                    <th scope="col">{{$t('Balance')}}</th>
                    <th scope="col">{{$t('account.actions')}}</th>
                </tr>
            </thead>
            <tbody class="size-14 text-center">
                    <tr v-for="cxc in cxcData" :key= "cxc.document">
                        <td><b>{{cxc.document }}</b></td>
                        <td>
                            <h6 class="size-14 mb-1">{{moment(cxc.creation).lang($i18n.locale).fromNow(true) | textFormat}}</h6>
                            <span class="color-dark-gray-200">{{ $d(new Date(cxc.creation), 'alt_short')  | textFormat}}</span>
                        </td>
                        <td class="color-main-red weight-600">{{cxc.total | moneyFormat}}</td>
                        <td class="balance weight-600">{{cxc.payment | moneyFormat }}</td>
                        <td class="color-main-red weight-600">{{cxc.balance | moneyFormat}}</td>
                        <td class="color-main-red weight-600">
                            <div v-if="Orderloading && cxc.document == document" style="justify-content: center; display: flex;">
                                <half-circle-spinner :animation-duration="1000" :size="20" color="color-main-red" class="me-1"/>
                            </div>
                            <button v-else :disabled="Orderloading" class="btn-invoice" @click="invoiceQuotation(cxc)">
                                <icon-invoice :size="12" class="me-2" color="var(--blue-600)"/>{{$t('account.invoice')}}
                            </button>
                        </td>
                    </tr>
            </tbody>
        </table>
        <without-results v-if="cxcData.length == 0 && !loading" class="mt-5" :description="$t('account.no_outstanding_balance')" 
            title="Balance">
            <template #main-icon>
                <div>
                    <icon-search-order-vue :size="90"/>
                </div>
            </template>
        </without-results>
    </div>
</div>
</template>



<script>
import WithoutResults from "../../../common/components/modals/WithoutResults.vue";
import { mapGetters } from "vuex"
import Lottie from "vue-lottie";
import { HalfCircleSpinner } from "epic-spinners";
import animationData from '../assets/animations/searching1.json';
import iconArrow from "../../../common/svg/iconArrow.vue";
import { CustomerService } from '../services/customer-service'
import iconSearchOrderVue from '../assets/svg/iconSearchOrder.vue';
import iconInvoice from "../assets/svg/iconInvoice.vue";
import { http } from "../../../common/services/web-backend";
const service = new CustomerService();

export default {
    name: "InvoiceBalance",
    components:{
        iconArrow,
        Lottie,
        iconSearchOrderVue,
        WithoutResults,
        iconInvoice,
        HalfCircleSpinner
    },
    data() {
        return{
            defaultOptions: { animationData: animationData, loop: true },
            cxcData: [],
            loading: true,
            Orderloading: false,
            document: ''
        }
    },
    computed:{
        ...mapGetters({
                user: "auth/userData",
        }),
    },
    methods:{
        goBack(){
            this.$router.go(-1)
        },
        invoiceQuotation(cxc){
            this.document = cxc.document
            this.getOrder(cxc.document)
        },
        async getOrder(document){
            try {
                this.Orderloading = true
                let response = await http.get('invoices/'+document)
                this.print(response.data.data)
            } catch (error) {
                this.Orderloading = false
                console.log(error);
            } finally {
                this.Orderloading = false
            }
        },
        async print(order) {
            if(order.document_id){
                this.$store.commit('account/SET_INVOICE', order)
                this.$router.push({path: '/account/invoice-intranet'})
            } else {
                this.$store.commit('account/SET_INVOICE', order)
                this.$router.push({path: '/account/invoice'})
            }
        }
    },
    async created() {
        try {
            const response = await service.getCxC()
            this.cxcData = response
            
        } catch (error) {
            console.error(error)
        }finally{
            this.loading = false
        }
    }
}

</script>

<style scoped>

    .balance {
        color: #85B561 !important;
        font-weight: 400;
    }
    .hover-blue svg {
        transition: var(--transition-1);
    }
    .hover-blue:hover svg{
        fill: var(--blue-600);
    }
    .quotes-table > thead {
        background-color: var(--light-gray-100);
        color: var(--dark-gray-200);
        position: sticky;
    }
    .quotes-table > thead > tr > th {
        font-weight: 400;
        font-size: 14px;
    }
    .table::v-deep > :not(:first-child) {
        border-top: 0;
    }
    .option-tooltip {
        border-radius: 6px;
        display: none;
    }
    .popup::v-deep::before {
        top: 25px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(134deg);
        width: 12px;
        height: 12px;
    }
    .btn-action {
        height: 30px;
        width: 30px;
    }
    .btn-action:hover > .option-tooltip, 
    .order-token-id:hover > .option-tooltip{
        display: block;
    }
    .icon-active {
        transform: rotate(-90deg) !important;
    }
    .btn-collapse {
        transform: rotate(90deg);
        transition: var(--transition-1);
    }
    .card-container {
        border-radius: 10px;
    }
    .btn-cart-disabled {
        cursor: no-drop
    }
    .btn-cart-disabled svg{
        fill: var(--gray-100) !important;
        cursor: no-drop;
    }
    .cancel{
        color: #878f96d8;
        background: #66829a14;
    }
    .cancel > svg {
        fill: #878f96d8;
    }
    .invoiced{
        background: #ebf7df;
        color: #89c654;
    }
    .invoiced > svg {
        fill: #89c654;
    }
    .pending{
        background: #fcf7e6;
        color: #e1b000;
    }
    .pending > svg {
        fill: #e1b000;
    }
    .focus-quotation{
        background-color: var(--light-gray-100);
    }
    input:focus {
        box-shadow: none;
    }
    .hithere {
        animation: hithere 1s ease infinite;
    }
    @keyframes hithere {
        30% { transform: scale(1.4); }
        40%, 60% { transform: rotate(-20deg) scale(1.4); }
        50% { transform: rotate(20deg) scale(1.4); }
        70% { transform: rotate(0deg) scale(1.4); }
        100% { transform: scale(1); }
    }
    .changed-quotes:last-child .separator-changed-quotes{
        display: none;
    }

    @media (max-width: 476px) {
        .quotes-table > tbody >  tr {
            white-space: nowrap;
        }
    }
    .select-container{
        width: 200px;
        height: 35px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .input-search::v-deep > input {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .amount-changed{
        text-decoration: line-through;
    }
    .btn-invoice{
        background-color: transparent;
        border: 1.5px solid var(--blue-600);
        color: var(--blue-600);
        height: 35px;
        border-radius: 7px;
        font-weight: bold;
    }
    .btn-invoice:disabled{
        border-color: var(--gray-1001) !important;
        cursor: no-drop;
        color: var(--gray-1001) !important
    }
    .btn-invoice:disabled > svg{
        fill:  var(--gray-1001);
    }
</style>