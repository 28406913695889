<template>
<icon-base :width="size + 'px'" :fill="color" viewBox="0 0 100 71.615">
    <g id="No_order_found" data-name="No order found" transform="translate(-387 -2081)">
        <ellipse id="Ellipse_424" data-name="Ellipse 424" cx="50" cy="4.1" rx="50" ry="4.1" transform="translate(387 2144.416)" fill="#f0f4f9"/>
        <path id="Path_2823" data-name="Path 2823" d="M2245.135,706.73,2236,697.593a12.505,12.505,0,1,0-3.072,3.071l9.137,9.137a2.172,2.172,0,1,0,3.085-3.058Zm-19.38-6.978a9.331,9.331,0,1,1,9.331-9.332h0a9.331,9.331,0,0,1-9.331,9.331Z" transform="translate(-1768.027 1434.428)" fill="#4979b2"/>
        <path id="Path_2824" data-name="Path 2824" d="M2203.926,693.362c-.264,0-.481-.321-.481-.719v-3.775c0-.4.216-.721.481-.721h7.094a14.729,14.729,0,0,1,.876-3.316h-18.212a.664.664,0,0,1-.584-.721v-3.775a.664.664,0,0,1,.584-.721h21.771a14.462,14.462,0,0,1,14.056-3.625V648.516a1.943,1.943,0,0,0-1.941-1.944h-18.183V663.8l-6.6-4.457-6.6,4.457V646.572h-18.179a1.944,1.944,0,0,0-1.944,1.941v47a4.5,4.5,0,0,0,4.5,4.5h33.806a14.857,14.857,0,0,1-3.283-6.659Z" transform="translate(-1768.027 1434.428)" fill="#212e3d"/>
    </g>
</icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'Settings',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
    }
}
</script>
<style scoped>
    .st0{fill:#F0F4F9;}
	.st1{fill:#4979B2;}
	.st2{fill:#212E3D;}
</style>
