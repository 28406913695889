import { http } from "../../../common/services/web-backend";
import { parseResponse } from "../../../common/helpers/responses/parser";
import store from "../../../store";

export class CustomerService {

    async getCxC(){
        try {
            const getPendingBalance = await http.get('/users/getCustomerCXC?only_balance=0');
            const res = parseResponse(getPendingBalance.data);
            return res.data
        }catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }

    }

    async getPaymentCards(page){
        try {
            console.log(store.getters['common/configurations'].payment_methods.stripe_backend_implementation.active);
            if(store?.getters['common/configurations']?.payment_methods?.stripe_backend_implementation?.active){
                const historyCards = await http.get(`order-payments?type=stripe&page=${page}`)
                const res = historyCards.data
                return res
            } else {
                const historyCards = await http.get(`order-payments?type=azul&page=${page}`)
                const res = historyCards.data
                return res
            }
        } catch (e) {
            if (e.response){
                throw parseResponse(e.response.data)
            }else{
                throw e
            }
        }
    }

    async getClient(search){
        try {
            const getClientInfo = await http.get(`/customers?q=${search}`);
            const response = getClientInfo.data
            return response.data
        }catch (e) {
            if (e.response){
                throw this.response
            }else{
                throw e
            }
        }
    }
}